




























import AppDialog from "@/components/Navigation/AppDialog.vue";
import Component, { mixins } from "vue-class-component";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { ResponsiveMixin } from "@/mixins/Responsive";

@Component({
  name: "broadcast",
  components: {
    AppDialog,
    LottieAnimation
  }
})
export default class Broadcast extends mixins(ResponsiveMixin) {
  get dialogOpen(): boolean {
    return this.$route.name !== "Broadcast";
  }
}
